// eslint-disable-next-line max-classes-per-file
import Model from '@/classes/Model'
import OfferModel from '@/modules/offer/offer.model'
import UserModel from '@/modules/user/user.model'
import IFundingRequestResponse from '@/modules/funding-request/funding-request-response.interface'
import { IFundingRequestType } from '@/interfaces/form'
import ISodraDataInterface from '@/modules/sodra/sodra-data.interface'
import IDebtDataInterface from '@/modules/debt/debt-data.interface'
import { IBaseStepsInterface } from '@/modules/steps/steps.interface'
import { getCorrectPhoneFormat } from '@/helpers/fundingRequest'

export enum FundingRequestAttribute {
  userPhone = 'userPhone',
  userEmail = 'userEmail',
  filterTypeKey = 'fundingType-key',
  type = 'type',
  fundingRequestNumber = 'funding_request_number',
  status = 'status',
  period = 'period',
  periodMeasure = 'period_measure',
  periodData = 'periodData',
  currentCommitmentsFunds = 'currentCommitmentsFunds',
  amount = 'amount',
  monthlyAmount = 'monthlyAmount',
  data = 'data',
  url = 'url',
  emailVerificationUrl = 'emailVerificationUrl',

  userUuid = 'userUuid',
  files = 'files',
  user = 'user',
  selectedOfferUuid = 'selectedOfferUuid',
  selectedOffer = 'selectedOffer',
  offers = 'offers',
  creditors = 'creditors',
  politics = 'politics',
  created_at = 'createdAt',
  confirmed_at = 'confirmed_at',
  password = 'password',
  password_confirmation = 'password_confirmation',
  waitingSpouseApproval = 'waiting-spouse-approval',
  assigned_users = 'assignedUsers',
  verifications = 'verifications',
  step = 'step',

  attachments = 'attachments',
  financier = 'financier',
  funding_type = 'funding_type',
  funding_type_name = 'funding_type_name',
  debts_data = 'debts_data',
  spouse_debts_data = 'spouse_debts_data',
  sodra_data = 'sodra_data',
  spouse_sodra_data = 'spouse_sodra_data',
  steps = 'steps',
  deposit = 'deposit',
}

export default class FundingRequestModel extends Model {
  [FundingRequestAttribute.userPhone]!: string;
  [FundingRequestAttribute.userEmail]!: string;
  [FundingRequestAttribute.type]!: string;
  [FundingRequestAttribute.fundingRequestNumber]!: number;
  [FundingRequestAttribute.status]!: string;
  [FundingRequestAttribute.period]!: number;
  [FundingRequestAttribute.periodMeasure]!: string;
  [FundingRequestAttribute.periodData]!: string;
  [FundingRequestAttribute.amount]!: number;
  [FundingRequestAttribute.monthlyAmount]!: number;
  [FundingRequestAttribute.currentCommitmentsFunds]!: number;
  [FundingRequestAttribute.creditors]!: any;
  [FundingRequestAttribute.politics]!: any;
  [FundingRequestAttribute.data]: any;
  [FundingRequestAttribute.url]: string;
  [FundingRequestAttribute.waitingSpouseApproval]: string;
  [FundingRequestAttribute.emailVerificationUrl]: string;
  [FundingRequestAttribute.password]: string;
  [FundingRequestAttribute.password_confirmation]: string;

  [FundingRequestAttribute.userUuid]!: string;
  [FundingRequestAttribute.user]!: UserModel;
  [FundingRequestAttribute.selectedOfferUuid]!: string;
  [FundingRequestAttribute.selectedOffer]!: OfferModel;
  [FundingRequestAttribute.offers]!: any;
  [FundingRequestAttribute.created_at]!: any;
  [FundingRequestAttribute.confirmed_at]!: any;
  [FundingRequestAttribute.assigned_users]!: any;
  [FundingRequestAttribute.verifications]!: any;

  [FundingRequestAttribute.attachments]!: object[];
  [FundingRequestAttribute.files]!: { filename: string; url: string }[];
  [FundingRequestAttribute.financier]!: any;
  [FundingRequestAttribute.funding_type]!: IFundingRequestType;
  [FundingRequestAttribute.debts_data]!: IDebtDataInterface;
  [FundingRequestAttribute.spouse_debts_data]!: IDebtDataInterface;
  [FundingRequestAttribute.sodra_data]!: ISodraDataInterface;
  [FundingRequestAttribute.spouse_sodra_data]!: ISodraDataInterface;
  [FundingRequestAttribute.steps]!: IBaseStepsInterface;
  [FundingRequestAttribute.deposit]!: number

  static DRAFT = 'draft'
  static WAITING_APPROVAL = 'waiting-approval'
  static MISSING_DATA = 'missing-data'
  static CONFIRMED = 'confirmed'
  static FINANCIER_CHOSEN = 'financier-chosen'
  static COMPLETE = 'complete'

  static STATUS_DRAFT = 'Juodraštis'
  static STATUS_WAITING_APPROVAL = 'Laukiama patvirtinimo'
  static STATUS_WAITING_SPOUSE_APPROVAL = 'Laukiama sutuoktinio patvirtinimo'
  static STATUS_MISSING_DATA = 'Trūksta duomenų'
  static STATUS_CONFIRMED = 'Gauta'
  static STATUS_FINANCIER_CHOSEN = 'Pasirinktas finansuotojas'
  static STATUS_COMPLETE = 'Sudaryta sutartis'
  static STATUS_DECLINED = 'Paraiška atmesta'

  static NEGATIVE_STATUSES = [
    FundingRequestModel.STATUS_DRAFT,
    FundingRequestModel.STATUS_WAITING_APPROVAL,
    FundingRequestModel.STATUS_WAITING_SPOUSE_APPROVAL,
    FundingRequestModel.STATUS_MISSING_DATA,
  ]

  transform(data: IFundingRequestResponse): FundingRequestModel {
    if (data.data_field && !data.data_field.have_children) {
      delete data.data_field.children
    }

    super.transform(data)

    this.userPhone = getCorrectPhoneFormat(
      (data.user && data.user.phone) || data.data_field.phone || data.data_field.company_person_phone || '',
    )
    this.userEmail =
      (data.user && data.user.email) || data.data_field.email || data.data_field.company_person_email || ''
    this.uuid = data.uuid
    this.type = data.type
    this.funding_request_number = data.funding_request_number
    this.status = data.status
    this.period = data.period
    this.period_measure = data.period_measure
    this.periodData = this.period ? `${this.period} ${this.period_measure}` : '-'
    this.files = data.files
    this.amount = data.amount
    this.currentCommitmentsFunds = data.current_commitments_funds
    this.monthlyAmount = data.monthly_amount
    this.data = data.data_field
    this.userUuid = data.user_uuid
    this.confirmed_at = data.confirmed_at
    this.user = data.user && new UserModel().transform(data.user)
    this.selectedOfferUuid = data.selected_offer_uuid
    this.selectedOffer = data.selected_offer && new OfferModel().transform(data.selected_offer)
    this.offers = data.offers
    this.creditors = data.creditors
    this.politics = data.politics
    this.assignedUsers = data.assigned_users
    this.verifications = data.verifications
    this.attachments = data.attachments
    this.financier = data.financier
    this.funding_type = data.funding_type
    this.debts_data = data.debts_data
    this.spouse_debts_data = data.spouse_debts_data
    this.sodra_data = data.sodra_data
    this.spouse_sodra_data = data.spouse_sodra_data
    this.steps = data.steps
    this.deposit = data.deposit

    if (data.data_field) {
      this.data.phone = data.data_field.phone || '+370'

      if (data.data_field.is_married && !data.data_field.for_personal_needs) {
        this.data.spouse_phone = data.data_field.spouse.spouse_phone || '+370'
        this.data.spouse_surname = data.data_field.spouse.spouse_surname || '-'
        this.data.spouse_name = data.data_field.spouse.spouse_name || '-'
        this.data.spouse_personcode = data.data_field.spouse.spouse_personcode || '-'
        this.data.spouse_email = data.data_field.spouse.spouse_email || '-'
        this.data.spouse_duties = data.data_field.spouse.spouse_duties || '-'
        this.data.spouse_workplace = data.data_field.spouse.spouse_workplace || '-'
      }
    }

    return this
  }
}

export class FundingRequestBusinessModel extends Model {
  [FundingRequestAttribute.type]!: string;
  [FundingRequestAttribute.fundingRequestNumber]!: number;
  [FundingRequestAttribute.status]!: string;
  [FundingRequestAttribute.period]!: number;
  [FundingRequestAttribute.periodMeasure]!: string;
  [FundingRequestAttribute.periodData]!: string;
  [FundingRequestAttribute.amount]!: number;
  [FundingRequestAttribute.monthlyAmount]!: number;
  [FundingRequestAttribute.politics]!: any;
  [FundingRequestAttribute.data]: any;
  [FundingRequestAttribute.url]: string;
  [FundingRequestAttribute.emailVerificationUrl]: string;
  [FundingRequestAttribute.password]: string;
  [FundingRequestAttribute.password_confirmation]: string;

  [FundingRequestAttribute.userUuid]!: string;
  [FundingRequestAttribute.user]!: UserModel;
  [FundingRequestAttribute.selectedOfferUuid]!: string;
  [FundingRequestAttribute.selectedOffer]!: OfferModel;
  [FundingRequestAttribute.offers]!: any;
  [FundingRequestAttribute.created_at]!: any;
  [FundingRequestAttribute.assigned_users]!: any;
  [FundingRequestAttribute.verifications]!: any;
  [FundingRequestAttribute.attachments]!: any;
  [FundingRequestAttribute.files]!: { filename: string; url: string }[];
  [FundingRequestAttribute.funding_type]!: IFundingRequestType

  static DRAFT = 'draft'
  static WAITING_APPROVAL = 'waiting-approval'
  static MISSING_DATA = 'missing-data'
  static CONFIRMED = 'confirmed'
  static FINANCIER_CHOSEN = 'financier-chosen'
  static COMPLETE = 'complete'

  static STATUS_DRAFT = 'Juodraštis'
  static STATUS_WAITING_APPROVAL = 'Laukiama patvirtinimo'
  static STATUS_WAITING_SPOUSE_APPROVAL = 'Laukiama sutuoktinio patvirtinimo'
  static STATUS_MISSING_DATA = 'Trūksta duomenų'
  static STATUS_CONFIRMED = 'Gauta'
  static STATUS_FINANCIER_CHOSEN = 'Pasirinktas finansuotojas'
  static STATUS_COMPLETE = 'Sudaryta sutartis'

  static COMPANY_PERSON_CEO = 'Vadovas'

  transform(data: IFundingRequestResponse): FundingRequestBusinessModel {
    super.transform(data)

    this.uuid = data.uuid
    this.type = data.type
    this.funding_request_number = data.funding_request_number
    this.status = data.status
    this.period = data.period
    this.period_measure = data.period_measure
    this.periodData = this.period ? `${this.period} ${this.period_measure}` : '-'
    this.amount = data.amount
    this.monthlyAmount = data.monthly_amount
    this.data = data.data_field
    this.data.company_shareholders = data.data_field.company_shareholders || [{ name: '', code: '', shares: '' }]
    this.data.company_assets = data.data_field.company_assets || [{ type: '', value: '', details: '' }]
    this.data.company_funding_guarantees = data.data_field.company_funding_guarantees
    this.userUuid = data.user_uuid
    this.user = data.user && new UserModel().transform(data.user)
    this.selectedOfferUuid = data.selected_offer_uuid
    this.selectedOffer = data.selected_offer && new OfferModel().transform(data.selected_offer)
    this.offers = data.offers
    this.politics = data.politics
    this.assignedUsers = data.assigned_users
    this.verifications = data.verifications

    this.data.company_person_phone = data.data_field.company_person_phone || '+370'
    this.data.company_phone = data.data_field.company_phone || '+370'
    this.data.company_person_email = data.data_field.company_person_email
    this.data.company_person = FundingRequestBusinessModel.COMPANY_PERSON_CEO
    this.attachments = data.attachments
    this.files = data.files
    this.funding_type = data.funding_type

    return this
  }
}
