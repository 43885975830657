



















import { Component, Inject, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/Form/Form.vue'
import http from '@/http'
import _get from '@/helpers/_get'
import FundingRequestModel from '@/modules/funding-request/funding-request.model'
import DataTable from '@/components/DataTable/DataTable.vue'
import DataTableBase from '@/classes/DataTable/DataTable'
import DataTableHeader from '@/classes/DataTable/DataTableHeader'
import { AxiosResponse } from 'axios'
import DataTableHeaderTypes from '@/classes/DataTable/DataTableHeaderTypes'
import { FinancierAttribute } from '@/modules/financier/financier.model'
import DataTableAction from '@/classes/DataTable/DataTableAction'
import { Actions } from '@/store/global/actions'
import { IDialog } from '@/store/global/helpers'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import permissions from '@/helpers/permissions'
import can from '@/helpers/can'
import { Getters } from '@/store/auth/getters'
import FormBase from '@/classes/Form/FormBase'
import AdditionalDocumentsDialog from '@/components/Offers/AdditionalDocumentsDialog.vue'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import AdditionalDocumentModel, {
  AdditionalDocumentAttribute,
} from '@/modules/additional-documents/additional-document.model'
import AdditionalDocumentsCreateDialog from '@/components/Offers/AdditionalDocumentsCreateDialog.vue'
import Tabs from '@/components/Tabs.vue'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import AdditionalQuestionsCreateDialog from '@/components/Offers/AdditionalQuestionsCreateDialog.vue'
import FundingRequestFinancierInfoModel, {
  FundingRequestFinancierInfoAttribute,
} from '@/modules/funding-request-financier-info/funding-request-financier-info.model'
import IFundingRequestFinancierInfoResponse from '@/modules/funding-request-financier-info/funding-request-financier-info-response.interface'
import RepeatNotificationDialog from '@/views/Business/FundingRequests/RepeatNotificationDialog.vue'
import AdditionalInfoProvideAdminDialog from '@/components/Offers/AdditionalInfoProvideAdminDialog.vue'
import AdditionalInfoProvideCommentDialog from '@/components/Offers/AdditionalInfoProvideCommentDialog.vue'

@Component({
  components: {
    PageHeader,
    Form,
    DataTable,
    Tabs,
  },
  methods: {
    _get,
    can,
  },
})
export default class Documents extends Vue {
  user: UserModel = this.$store.getters[Getters.getUser]
  form!: FormBase
  permissions = permissions
  actions: DataTableAction[] = [
    new DataTableAction()
      .setPermissions([permissions.offers.edit])
      .setIcon('assignment')
      .setTitle('Peržiūrėti')
      .setAction(this.openAdditionalDocuments),
    new DataTableAction()
      .setPermissions([permissions.additionalDocuments.delete])
      .setIcon('cancel')
      .setTitle('Ištrinti')
      .setAction(this.deleteItem),
  ]
  @Inject('fundingRequest') getFundingRequest!: () => FundingRequestModel
  additionalDocuments: AdditionalDocumentModel[] = []
  additionalDocumentsTable: DataTableBase = new DataTableBase()
    .setModel(FundingRequestFinancierInfoModel)
    .setEndpoint(`/funding-requests/${this.$router.currentRoute.params.uuid}/additional-documents`)
    .setHeaders([
      new DataTableHeader()
        .setType(DataTableHeaderTypes.image)
        .setValue(`${AdditionalDocumentAttribute.financier}.${FinancierAttribute.logo}`)
        .setText('Logotipas')
        .setSortable(false)
        .setMeta({ width: '40px', height: '40px' }),
      new DataTableHeader()
        .setValue(`${AdditionalDocumentAttribute.financier}.${FinancierAttribute.name}`)
        .setText('Finansuotojas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${FundingRequestFinancierInfoAttribute.additionalDocument}.${AdditionalDocumentAttribute.title}`)
        .setText('Dokumento tipas')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${FundingRequestFinancierInfoAttribute.attachments}.length`)
        .setText('Pateiktų dokumentų skaičius')
        .setSortable(false),
      new DataTableHeader()
        .setValue(`${FundingRequestFinancierInfoAttribute.question}`)
        .setText('Klausimas')
        .setSortable(true),
      new DataTableHeader()
        .setValue(`${FundingRequestFinancierInfoAttribute.remarks}`)
        .setText('Pastaba')
        .setSortable(true),
      new DataTableHeader()
        .setValue(`${FundingRequestFinancierInfoAttribute.answer}`)
        .setText('Atsakymas')
        .setSortable(true),
      new DataTableHeader()
        .setValue(`${FundingRequestFinancierInfoAttribute.comment}`)
        .setText('Administratoriaus komentaras')
        .setSortable(true),
    ])

  created(): void {
    this.additionalDocumentsTable.loading = true
    this.form = new FormBase()
      .setEndpoint('additional-documents')
      .setUuid(this.$router.currentRoute.params.uuid)
      .setModel(FundingRequestModel)
      .setOnSuccess(this.onSuccess)
    this.addAction()
  }

  get fundingRequest(): FundingRequestModel {
    return this.getFundingRequest()
  }

  addAction(): void {
    if (this.user[UserAttribute.isAdmin]) {
      this.actions.push(
        new DataTableAction()
          .setPermissions([permissions.offers.edit])
          .setIcon('local_offer')
          .setTitle('Pateikti informacija')
          .setAction(this.openInformationSubmitionModal),
        new DataTableAction()
          .setPermissions([permissions.offers.edit])
          .setIcon('comment')
          .setTitle('Pateikti komentarą')
          .setAction(this.openCommentSubmitionModal),
      )
    }

    this.additionalDocumentsTable.setActions(this.actions)
  }

  openCommentSubmitionModal(element: FundingRequestFinancierInfoModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AdditionalInfoProvideCommentDialog,
      maxWidth: 650,
      meta: {
        uuid: element.uuid,
        funding_request: this.fundingRequest,
        onSuccess: () => {
          this.additionalDocumentsTable.loading = true
          http
            .get(`/funding-requests/${this.$router.currentRoute.params.uuid}/additional-documents`)
            .then((response: AxiosResponse) => {
              const data: FundingRequestFinancierInfoModel[] = response.data.data.map(
                (data: IFundingRequestFinancierInfoResponse) => new FundingRequestFinancierInfoModel().transform(data),
              )
              this.additionalDocumentsTable.setData(data)
              this.additionalDocumentsTable.loading = false

              this.$store.dispatch(Actions.showSnackbar, {
                type: 'success',
                message: `Komentaras sėkmingai pateikta!`,
              })
            })
        },
      },
    } as IDialog)
  }

  deleteItem(item: FundingRequestFinancierInfoModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite?',
        text: `Paspausdami taip mygtuką jūs ištrinsite pasiūlymą? Šis veiksmas negalės būti atšauktas.`,
        onYes: () => this.delete(item),
      },
    })
  }

  delete(item: FundingRequestFinancierInfoModel): Promise<DataTableBase> {
    return http
      .delete(`additional-information/delete/${item.uuid}`)
      .then(() => this.additionalDocumentsTable.removeItem(item))
  }

  openInformationSubmitionModal(element: FundingRequestFinancierInfoModel): void {
    let type = 'document'

    if (!element[FundingRequestFinancierInfoAttribute.additionalDocumentUuid]) {
      type = 'question'
    }

    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AdditionalInfoProvideAdminDialog,
      maxWidth: 650,
      meta: {
        type,
        uuid: element.uuid,
        funding_request: this.fundingRequest,
        question: element[FundingRequestFinancierInfoAttribute.question],
        additional_document_uuid: element[FundingRequestFinancierInfoAttribute.additionalDocumentUuid],
        onSuccess: () => {
          this.additionalDocumentsTable.loading = true
          http
            .get(`/funding-requests/${this.$router.currentRoute.params.uuid}/additional-documents`)
            .then((response: AxiosResponse) => {
              const data: FundingRequestFinancierInfoModel[] = response.data.data.map(
                (data: IFundingRequestFinancierInfoResponse) => new FundingRequestFinancierInfoModel().transform(data),
              )
              this.additionalDocumentsTable.setData(data)
              this.additionalDocumentsTable.loading = false

              this.$store.dispatch(Actions.showSnackbar, {
                type: 'success',
                message: `Informacija sėkmingai pateikta!`,
              })
            })
        },
      },
    } as IDialog)
  }

  addAdditionalDocuments(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AdditionalDocumentsCreateDialog,
      maxWidth: 650,
      meta: {
        additionalDocuments: this.additionalDocuments,
        user: this.user,
        fundingRequestUuid: this.getFundingRequest() && this.getFundingRequest().uuid,
        onSuccess: () => {
          this.additionalDocumentsTable.loading = true
          http
            .get(`/funding-requests/${this.$router.currentRoute.params.uuid}/additional-documents`)
            .then((response: AxiosResponse) => {
              const data: FundingRequestFinancierInfoModel[] = response.data.data.map(
                (data: IFundingRequestFinancierInfoResponse) => new FundingRequestFinancierInfoModel().transform(data),
              )
              this.additionalDocumentsTable.setData(data)
              this.additionalDocumentsTable.loading = false
            })
        },
      },
    } as IDialog)
  }

  openAdditionalQuestionDialog(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AdditionalQuestionsCreateDialog,
      maxWidth: 650,
      meta: {
        additionalDocuments: this.additionalDocuments,
        user: this.user,
        fundingRequestUuid: this.getFundingRequest() && this.getFundingRequest().uuid,
        onSuccess: () => {
          this.additionalDocumentsTable.loading = true
          http
            .get(`/funding-requests/${this.$router.currentRoute.params.uuid}/additional-documents`)
            .then((response: AxiosResponse) => {
              const data: FundingRequestFinancierInfoModel[] = response.data.data.map(
                (data: IFundingRequestFinancierInfoResponse) => new FundingRequestFinancierInfoModel().transform(data),
              )
              this.additionalDocumentsTable.setData(data)
              this.additionalDocumentsTable.loading = false
            })
        },
      },
    } as IDialog)
  }

  onSuccess(): void {
    this.$store.dispatch(Actions.showSnackbar, {
      type: 'success',
      message: 'Sėkmingai išsaugota!',
    })
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.fundingRequestsIndex })
  }

  onNotify(item: FundingRequestFinancierInfoModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite pakartotinai išsiųsti pranešimus?',
        onYes: () => this.notify(item),
      },
    })
  }

  notify(item: FundingRequestFinancierInfoModel): void {
    http
      .post(`funding-requests/${this.$router.currentRoute.params.uuid}/repeat-document-notification/${item.uuid}`)
      .then(() => {
        this.$store.dispatch(Actions.showSnackbar, {
          type: 'success',
          message: 'Klientas sėkmingai informuotas!',
        })
      })
  }

  openAdditionalDocuments(item: FundingRequestFinancierInfoModel): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AdditionalDocumentsDialog,
      maxWidth: 1250,
      meta: {
        user: this.$store.getters[Getters.getUser],
        entry: item.attachments,
      },
    } as IDialog)
  }

  onSendNotifications(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: RepeatNotificationDialog,
      maxWidth: 650,
    })
  }
}
