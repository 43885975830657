






















































































































































































































































































import { Component, Provide, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import http from '@/http'
import _get from '@/helpers/_get'
import FundingRequestModel, {
  FundingRequestAttribute,
  FundingRequestBusinessModel,
} from '@/modules/funding-request/funding-request.model'
import DataTable from '@/components/DataTable/DataTable.vue'
import FundingRequestFieldModel, {
  FundingRequestFieldAttribute,
} from '@/modules/funding-request-field/funding-request-field.model'
import IFundingRequestResponse from '@/modules/funding-request/funding-request-response.interface'
import IFundingRequestFieldResponse from '@/modules/funding-request-field/funding-request-field-response.interface'
import { FieldTypes } from '@/components/Form/FieldTypes'
import { AxiosError, AxiosResponse } from 'axios'
import { Actions } from '@/store/global/actions'
import { AdminRoutes } from '@/helpers/getRouteMeta'
import _ from 'lodash'
import permissions from '@/helpers/permissions'
import can from '@/helpers/can'
import { getFundingRequestStatusTitle } from '@/helpers/fundingRequest'
import AreYouSureDialog from '@/components/Dialogs/AreYouSureDialog.vue'
import { IDialog } from '@/store/global/helpers'
import OverwriteOfferDialog from '@/components/Offers/OverwriteOfferDialog.vue'
import { getOfferStatuses } from '@/helpers/offers'
import UserModel, { UserAttribute } from '@/modules/user/user.model'
import { Getters } from '@/store/auth/getters'
import VerificationModel, { VerificationAttribute } from '@/modules/verification/verification.model'
import { FinancierAttribute } from '@/modules/financier/financier.model'
import Tabs from '@/components/Tabs.vue'
import ITabs from '@/interfaces/tabs'
import $t from '@/helpers/translations'

@Component({
  components: {
    PageHeader,
    DataTable,
    Tabs,
  },
  methods: {
    $t,
    _get,
    can,
  },
})
export default class RegularSelectedOffersShow extends Vue {
  permissions = permissions
  fundingRequest: FundingRequestModel | null = null
  fundingRequestFields: FundingRequestFieldModel[] | null = null
  fundingRequestState: boolean | false = false
  user: UserModel = this.$store.getters[Getters.getUser]

  tabs: ITabs[] = [
    {
      to: AdminRoutes.selectedOffersShowTabOffers,
      title: 'Pasiūlymai',
      permissions: [permissions.fundingRequests.show],
    },
    {
      to: AdminRoutes.selectedOffersShowTabDocuments,
      title: 'Papildomi dokumentai',
      permissions: [permissions.fundingRequests.show],
    },
    {
      to: AdminRoutes.selectedOffersShowTabVerifications,
      title: 'Tapatybės patvirtinimai',
      permissions: [permissions.fundingRequests.show],
    },
    {
      to: AdminRoutes.selectedOffersShowTabActivityLog,
      title: 'Paraiškos veiksmų istorija',
      permissions: [permissions.fundingRequests.show],
    },
  ]

  politics = {
    head_of_state:
      'Valstybės vadovas, vyriausybės vadovas, ministras, viceministras arba ministro pavaduotojas, valstybės sekretorius, parlamento, vyriausybės arba ministerijos kancleris',
    member_of_parliament: 'Parlamento narys',
    member_of_the_supreme_court:
      'Aukščiausiųjų teismų, konstitucinių teismų ar kitų aukščiausiųjų teisminių institucijų, kurių sprendimai negali būti skundžiami, narys',
    mayor_of_the_municipality: 'Savivaldybės meras, savivaldybės administracijos direktorius',
    member_of_the_board:
      'Aukščiausiosios valstybių audito ir kontrolės institucijos valdymo organo narys ar centrinio banko valdybos pirmininkas, jo pavaduotojas ar valdybos narys',
    ambassador:
      'Ambasadorius, laikinasis reikalų patikėtinis, nepaprastasis pasiuntinys ir įgaliotasis ministras ar aukšto rango ginkluotųjų pajėgų karininkas',
    member_of_a_state_owned_enterprise:
      'Valstybės įmonės, akcinės bendrovės, uždarosios akcinės bendrovės, kurių akcijos ar dalis akcijų, suteikiančių daugiau kaip 1/2 visų balsų šių bendrovių visuotiniame akcininkų susirinkime, priklauso valstybei nuosavybės teise, valdymo ar priežiūros organo narys',
    member_of_a_municipal_enterprise:
      'Savivaldybės įmonės, akcinės bendrovės, uždarosios akcinės bendrovės, kurių akcijos ar dalis akcijų, suteikiančių daugiau kaip 1/2 visų balsų šių bendrovių visuotiniame akcininkų susirinkime, priklauso savivaldybei nuosavybės teise ir kurios laikomos didelėmis įmonėmis pagal Lietuvos Respublikos įmonių finansinės atskaitomybės įstatymą, valdymo ar priežiūros organo narys',
    head_of_an_international_intergovernmental_organization:
      'Tarptautinės tarpvyriausybinės organizacijos vadovas, jo pavaduotojas, valdymo ar priežiūros organo narys',
  }

  @Provide('fundingRequest') getFundingRequest(): FundingRequestBusinessModel | null {
    return this.fundingRequest
  }

  created(): void {
    this.loadFundingRequest()
  }

  get additionalSpouseDocuments(): any[] {
    const documentTypes: string[] = [
      'spouse_work_certificate',
      'spouse_bank_statement',
      'spouse_income_and_expenses_documents',
      'spouse_income_declaration',
      'spouse_business_certificate',
    ]

    return _.filter(this.fundingRequest?.attachments, (item: any) => documentTypes.includes(item.group))
  }

  get additionalDocuments(): any[] {
    const documentTypes: string[] = [
      'work_certificate',
      'bank_statement',
      'income_and_expenses_documents',
      'income_declaration',
      'business_certificate',
    ]

    return _.filter(this.fundingRequest?.attachments, (item: any) => documentTypes.includes(item.group))
  }

  get additionalRealEstateDocuments(): any[] {
    const documentTypes: string[] = ['real_estate_documents', 'real_estate_rating', 'pictures']

    return _.filter(this.fundingRequest?.attachments, (item: any) => documentTypes.includes(item.group))
  }

  loadFundingRequest(): void {
    http.get(`/funding-requests/${this.$router.currentRoute.params.uuid}`).then(
      (
        response: AxiosResponse<{
          funding_request: IFundingRequestResponse
          funding_request_fields: IFundingRequestFieldResponse[]
        }>,
      ) => {
        this.fundingRequestFields = response.data.funding_request_fields.map((item: IFundingRequestFieldResponse) =>
          new FundingRequestFieldModel().transform(item),
        )
        this.fundingRequest = new FundingRequestModel().transform(response.data.funding_request)
        this.fundingRequestState =
          getFundingRequestStatusTitle(response.data.funding_request.status) === FundingRequestModel.STATUS_COMPLETE
      },
    )
  }

  get getBankAccount(): string {
    if (this.fundingRequest && this.fundingRequest[FundingRequestAttribute.verifications]) {
      const innerData = this.fundingRequest.data

      if (!innerData) {
        return '-'
      }

      const verification: VerificationModel = this.fundingRequest[FundingRequestAttribute.verifications].find(
        (el) => el.personcode === parseInt(innerData.personcode || 0, 10),
      )

      return verification && verification[VerificationAttribute.account]
        ? verification[VerificationAttribute.account]
        : '-'
    }

    return '-'
  }

  get hasAbilityToViewBankAccount(): boolean {
    if (this.user[UserAttribute.isAdmin]) {
      return true
    }

    return (
      this.user[UserAttribute.financier] && this.user[UserAttribute.financier][FinancierAttribute.requiredBankAccount]
    )
  }

  triggerDeclineDialog(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai norite atsisakyti sudaryti sutartį?',
        text: `Veiksmas negalės būti atšauktas.`,
        onYes: () => this.declineContract(),
      },
    })
  }

  overwriteOffer(): void {
    if (this.fundingRequest && this.fundingRequest.selectedOffer) {
      this.$store.dispatch(Actions.showDialog, {
        show: true,
        component: OverwriteOfferDialog,
        maxWidth: 650,
        meta: {
          entry: this.fundingRequest.selectedOffer,
          fundingRequest: this.fundingRequest,
          fundingRequestUuid: this.fundingRequest && this.fundingRequest.uuid,
          onSuccess: () => this.loadFundingRequest(),
        },
      } as IDialog)
    }
  }

  parseOfferStatus(status: string): string {
    return getOfferStatuses(status)
  }

  isSignedDocument(itemGroup): boolean {
    return ['user-signed-documents', 'old-user-signed-documents'].includes(itemGroup)
  }

  hasSignedDocuments(): any {
    if (this.fundingRequest?.offers) {
      const signedDocuments: any = []

      this.fundingRequest?.offers.forEach((item: any) => {
        if (
          item.user_signed_documents.find((documentItem) =>
            ['user-signed-documents', 'old-user-signed-documents'].includes(documentItem.group),
          )
        ) {
          signedDocuments.push(item)
        }
      })

      return signedDocuments.length > 0
    }

    return false
  }

  triggerDialog(): void {
    this.$store.dispatch(Actions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: 'Ar tikrai sudarėte sutartį?',
        text: `Paspausdami taip mygtuką jūs pažymėsite, jog sutartis sudaryta, veiksmas negalės būti atšauktas.`,
        onYes: () => this.complete(),
      },
    })
  }

  complete(): void {
    const uuid = this.fundingRequest && this.fundingRequest.uuid
    http.post(`/funding-requests/${uuid}/complete`).then(() => {
      this.fundingRequestState = true
      this.$store.dispatch(Actions.showSnackbar, {
        type: 'success',
        message: 'Sutartis sėkmingai sudaryta!',
      })
    })
  }

  declineContract(): void {
    const uuid = this.fundingRequest && this.fundingRequest.uuid
    http.post(`/funding-requests/${uuid}/decline`).then(() => {
      this.$store.dispatch(Actions.showSnackbar, {
        type: 'success',
        message: 'Sutartis atšaukta!',
      })
    })
  }

  getValue(data: object, field: FundingRequestFieldModel): string | false {
    if (field.type === 'json') {
      return (field[FundingRequestFieldAttribute.show] = false)
    }

    if (field.type === FieldTypes.select) {
      const found = field.options.find((option: any) => option.value === _.get(data, field.key))

      return found ? found.label : _.get(data, field.key, (field[FundingRequestFieldAttribute.show] = false))
    }

    if (field.type === FieldTypes.checkbox) {
      return _.get(data, field.key) ? 'Taip' : 'Ne'
    }

    if (
      (field.key === 'email' ||
        field.key === 'phone' ||
        field.key === 'spouse_email' ||
        field.key === 'spouse_phone') &&
      this.fundingRequest &&
      !this.fundingRequest.hasDynamicPermissions([permissions.fundingRequests.complete]) &&
      !this.user[UserAttribute.isAdmin]
    ) {
      return '*******'
    }

    if (field.type === FieldTypes.number) {
      const amount = _.get(data, field.key, null)

      if (amount === null) {
        field[FundingRequestFieldAttribute.show] = false
      }

      return amount
    }

    return _.get(data, field.key) || (field[FundingRequestFieldAttribute.show] = false)
  }

  goToList(): void {
    this.$router.push({ name: AdminRoutes.selectedOffersIndex })
  }

  get isSelectedFinancierOrAdmin(): boolean {
    if (this.user.isAdmin) return true

    return this.fundingRequest
      ? this.fundingRequest.selectedOffer.financier_uuid === this.user[UserAttribute.financier].uuid
      : true
  }

  downloadLoanDocument(): void {
    if (this.fundingRequest && this.fundingRequest.uuid) {
      http(`funding-requests/${this.fundingRequest.uuid}/get-signed-document`, {
        responseType: 'blob',
        method: 'GET',
      })
        .then(async (response) => {
          const fileName = response.headers['content-disposition'].split('filename=')[1]

          const blobUrl = URL.createObjectURL(response.data)

          const link = document.createElement('a')

          link.setAttribute('href', blobUrl)
          link.setAttribute('download', fileName)
          link.click()
        })
        .catch(async (error: AxiosError) => {
          if (error.response) {
            await this.$store.dispatch(Actions.showSnackbar, {
              type: 'error',
              message: error.response.data.message,
            })
          }
        })
    }
  }

  downloadPersonReport(): void {
    if (this.fundingRequest && this.fundingRequest.uuid) {
      http(`funding-requests/${this.fundingRequest.uuid}/get-person-report`, {
        responseType: 'blob',
        method: 'GET',
      })
        .then(async (response) => {
          const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/['"]+/g, '')

          const blobUrl = URL.createObjectURL(response.data)

          const link = document.createElement('a')

          link.setAttribute('href', blobUrl)
          link.setAttribute('download', fileName)
          link.click()
        })
        .catch(async (error: AxiosError) => {
          if (error.response) {
            await this.$store.dispatch(Actions.showSnackbar, {
              type: 'error',
              message: error.response.data.message,
            })
          }
        })
    }
  }

  get hasPoliticallyImportantPerson(): boolean {
    const politicalPerson = this.fundingRequest?.data?.politically_important_person

    return !!Object.keys(politicalPerson).length && politicalPerson.political_person_name !== ''
  }
}
